import React, {useContext} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {Template} from "./Template";
import {TabPanel} from "../../../../components/TabPanel";
import {ActivityContext} from "../../ActivityContext";
import {SendApprovalEmailDialog} from "./dialogs";
import {TemplateContext} from "./TemplateContext";
import {TemplateSuiteContext} from "./TemplateSuiteContext";
function a11yProps(index: number) {
    return {
        id: `template-tab-${index}`,
        'aria-controls': `template-tabpanel-${index}`,
    };
}
// @ts-ignore
export const TemplateTabs = ({country}: {country?: "SE" | "DK" | "FI" | "NO" | ""}) => {
    const {activity} = useContext(ActivityContext);
    const {handleTemplateSuiteChange, handleTemplateUpdateV2, footer, isUsingFragmentFooter, handleTemplateFieldUpdate, templateSuite} = useContext(TemplateSuiteContext);
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const isSelected = (template: string) => {
        if(template === "E-MAIL SAVE-THE-DATE") return activity.selectedTemplates.includes('Save the date')
        if(template === "E-MAIL INVITATION") return activity.selectedTemplates.includes('Invitation') || templateSuite.type?.match(/(RepEmail|BookingEmail)/)
        if(template === "REGISTRATION") return activity.selectedTemplates.includes('Registration')
        if(template === "E-MAIL WAITING LIST") {
            const registrationTemplate = templateSuite.templates?.find( (t: TemplateType) => t.type === 'REGISTRATION');
            const limitationStatus = registrationTemplate?.limitation?.status;
            return (activity.selectedTemplates.includes('Registration') && (limitationStatus && limitationStatus !== 'no-limitation'))
        }
        if(template === "E-MAIL CONFIRMATION") return activity.selectedTemplates.includes('Registration')
        if(template === "E-MAIL WEBINAR URL") return activity.selectedTemplates.includes('Webinar')
        if(template === "E-MAIL PRE-EVENT INFO") return activity.selectedTemplates.includes('Pre-event info')
        if(template === "E-MAIL SURVEY") return activity.selectedTemplates.includes('Survey')
        if(template === "SURVEY") return activity.selectedTemplates.includes('Survey')
        if(template === "PRINT INVITATION") return activity.selectedTemplates.includes('Print invitation')
        if(template === "SURVEY SLIDE") return activity.selectedTemplates.includes('Survey')
    }
    return (
        <Box sx={{ width: '100%' }}>
            {
                !['RepEmail', 'BookingEmail'].includes(templateSuite.type??'') &&
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <SendApprovalEmailDialog activity={activity} country={country} />
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"   variant="scrollable"
                          scrollButtons="auto">

                        {// @ts-ignore
                            templateSuite.templates ?
                                templateSuite.templates.filter( (t: TemplateType) => isSelected(t.type)).map( (template: TemplateType, index: number) => {
                                    return (
                                        <Tab key={template._id} label={ templateSuite.type === 'RepEmail' ? 'REP E-MAIL' : template.type} {...a11yProps(index)} />
                                    )
                                }) : ''
                        }
                    </Tabs>
                </Box>
            }
            {// @ts-ignore
                templateSuite.templates ? templateSuite.templates.filter( (t: TemplateType) => isSelected(t.type)).map( (template, index) => {
                    return (
                        <TabPanel value={value} index={index} key={template._id} >
                            <TemplateContext.Provider value={{
                                templateSuite: activity.templateSuite,
                                handleTemplateSuiteChange,
                                handleTemplateUpdateV2,
                                handleTemplateFieldUpdate,
                                footer,
                                isUsingFragmentFooter,
                                template: template!,
                                country: country!
                            }} >
                                <Template key={template._id}/>
                            </TemplateContext.Provider>
                        </TabPanel>
                    )
                }) : ''}

        </Box>
    )
}
