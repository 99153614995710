import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {RichTextField} from "../../../../../../components/EditDialog";
import React, {useEffect} from "react";
interface LimitCategoryProps {
    limitation: LimitationType;
    setSelectedField: (field: TemplateFieldType | undefined) => void;
    templateFields: TemplateFieldType[];
    disabled: boolean;
    categoryFields: TemplateFieldType[];
    selectedField: TemplateFieldType | undefined;
    save: (limitation: LimitationType) => void;
    setTotalCategoryLimits: (total: number) => void;
    error: boolean;
    setError: (error: boolean) => void;
    totalCategoryLimits: number;
    setCategoryFields: (fields: TemplateFieldType[]) => void;
}
export const LimitCategory = (
    {limitation, setSelectedField, templateFields, disabled, categoryFields, selectedField, save, setTotalCategoryLimits, error, setError, totalCategoryLimits, setCategoryFields}: LimitCategoryProps) => {

    const calculateTotalCategoryLimits = () => {
        if (limitation?.details?.values?.length) {
            const total = limitation.details.values.reduce((acc: any, curr: any) => acc + Number((curr?.max)), 0);
            setTotalCategoryLimits(total);
            if (total > limitation.max) setError(true);
            else setError(false);
        }

    }
    const handleLimitationDetailValuesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, choice: string) => {
        const inputVal = event.target.value ? Number(event.target.value) : undefined;
        const field = selectedField?.choices?.find( (ch: string) => choice === ch);
        const values = limitation.details?.values?.map( (value: LimitationDetailValuesType) => value.value === field ? {...value, max: inputVal} : value);
        save({...limitation, details: {...limitation.details, values}});
    }
    const handleCategoryLimitMessageChange = (value: string) => {
        save({...limitation, details: {...limitation.details, message: value}});
    };
    useEffect(calculateTotalCategoryLimits, [limitation])
    useEffect(() => {
        setCategoryFields(templateFields?.filter( (field: TemplateFieldType) => field.type === 'Radiobutton'));
    }, [])

    useEffect(() => {
        setCategoryFields(templateFields?.filter( (field: TemplateFieldType) => field.type === 'Radiobutton'));
    }, [templateFields])
    return (<>
    <Grid item xs={3} >
        <FormControl fullWidth variant="standard">
            <InputLabel sx={{fontSize: '14px'}}>
                Select category field
            </InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                id="limitation.details.field"
                value={limitation.details?.field??''}
                onChange={event => setSelectedField(templateFields.find( (field: TemplateFieldType) => field.name === event.target.value))}
                label="Select category field"
                sx={{fontSize: '14px'}}
                disabled={disabled}
            >
                <MenuItem sx={{fontSize: '14px'}} value={'empty'}>{' '}</MenuItem>
                {
                    categoryFields.map( (field: TemplateFieldType) => <MenuItem key={field.value} sx={{fontSize: '14px'}} value={field.name}>{field.value}</MenuItem>)
                }

            </Select>
        </FormControl>
    </Grid>
    {
        selectedField?.choices?.length &&
        selectedField?.choices?.map( (choice: string, index: number) => {
            const max = limitation.details?.values?.find( (value: LimitationDetailValuesType) => value.value === choice)?.max
            return (
                <Grid item xs={9 / selectedField.choices!.length!} key={index} paddingLeft={3}>
                    <TextField
                        variant="standard"
                        fullWidth
                        inputProps={{style: {fontStyle: '14px'}, max: limitation.max }}
                        label={`Max number of "${choice}"`}
                        id={'limitation.details.values.'+choice}
                        disabled={disabled}
                        onChange={ (event => handleLimitationDetailValuesChange(event, choice))}
                        value={max}
                        onBlur={ (event => {
                                if (totalCategoryLimits > limitation.max) {
                                    setError(true);
                                    return;
                                }
                                setError(false);
                                save(limitation);
                            }
                        )}
                        type="number"
                        helperText={error ? `Total number cannot exceed ${limitation.max}` : ''}
                        sx={{
                            fontSize: '14px',
                            '& .MuiInputBase-root': {
                                color: error ? 'red' : 'inherit',
                            },
                            '& .MuiInputLabel-root': {
                                color: error ? 'red' : 'inherit',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: error ? 'red' : 'inherit',
                                },
                                '&:hover fieldset': {
                                    borderColor: error ? 'red' : 'inherit',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: error ? 'red' : 'primary.main',
                                },
                            }
                        }}
                    />

                </Grid>
            )
        })
    }
    <Grid item xs={12} p={2} component="div" sx={{ '& > span': { display: 'inline-block', marginY: '8px' }, minHeight: '90px', width: '750px' }}>
        <Typography component="div" variant="body1" style={{fontSize: '14px', color: 'gray', paddingBottom: '10px'}}>
            Message to display when the category limit is reached:
        </Typography>
        <RichTextField maxLength={500} onBlur={handleCategoryLimitMessageChange} defaultValue={limitation.details?.message} readOnly={disabled} fieldId={'limit.details.message'} />
    </Grid>
</>)
}