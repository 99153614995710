import React, {useContext, useEffect, useRef, useState} from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useSnackbar } from 'notistack';
import {Details} from "./components/Details";
import {Customers} from "./components/Customers";
import {Templates} from "./components/Templates";
import {Approval} from "./components/Approval";
import {Fade, Typography, useMediaQuery} from "@mui/material";
import {
    getActivity,
    saveActivity,
    webSocketHandler,
    copyActivity, deleteActivity, archiveActivity, getUsers
} from "./methods";
import {useNavigate, useParams} from "react-router-dom";
import {Invitation} from "./components/Invitation";
import {SendMeetingDetails} from "./components/Meeting URL";
import {Survey} from "./components/Survey";
import {SendSurvey} from "./components/Survey/SendSurvey";
import {sendErrorMessage} from "../../Error";
import useWebSocket  from "react-use-websocket";
import {RepEmails} from "./components/RepEmail";
import {ActivityContext} from "./ActivityContext";
import {AppContext} from "../../AppContext";
import {Summary} from "./components/Summary";
import {format, parseISO} from "date-fns";
import {ConfirmPrompt as ConfirmArchive} from "../../components/ConfirmPrompt";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';
import {compareObjects} from "../../components/CompareObjects";
import { handlePayload } from './functions/handlePayload';
import Grid from "@mui/material/Grid";
import {ActionBar} from "../../views/common/ActionBar";
import {useTheme} from "@mui/material/styles";
import {SendReminder} from "./components/Reminder";
import {PreEventInfo} from "./components/PreEventInfo";
import {SaveTheDate} from "./components/SaveTheDate";

import { useLocation } from 'react-router-dom';
const socketUrl =`${process.env.REACT_APP_WEBSOCKET_API}`;
export enum Steps {
    Details,
    Templates,
    Approval,
    Customers,
    RepEmail,
    SaveTheDate,
    Invitation,
    Reminder,
    PreEventInfo,
    WebinarUrl,
    SendSurvey,
    Survey,
    Summary

}
const defaultSteps = [
    {label: 'Details',  component: Steps.Details},
    {label: 'Templates',  component: Steps.Templates},
    {label: 'Approval',  component: Steps.Approval}
]
// @ts-ignore
export const Activity = () => {
    const params = useParams();
    const queryParams = new URLSearchParams(useLocation().search);
    const history = useNavigate();
    const {user, setAppHeader, isEmbedded, setWaitingForBackendMessage, setActions, userHasRole, actions, userHasCountries} = useContext(AppContext);
    const [externalOrigin] = useState(queryParams.get('externalOrigin')??false);
    const [isEditor, setIsEditor] = React.useState(false)
    const [repEmailEditor, setRepEmailEditor] = React.useState<any>({
        editor: false,
        country: user?.countries && user.countries[0]
    })
    const [activityId, setActivityId] = React.useState('');
    const [activity, setActivity] = React.useState<ActivityType | null>(null);
    const [participants, setParticipants] = React.useState<ParticipantType | any>([]);
    const [activityPayload, setActivityPayload] = React.useState<any>({
        refreshActivity: false,
        diff: {},
        payload: {},
        templateSuitePayload: {},
        templatePayload: {},
        templateFieldPayload: {}
    });
    const [activeStep, setActiveStep] = React.useState(0);
    const [currentStep, setCurrentStep] = React.useState(0);
    const [isReadOnly, setIsReadOnly] = React.useState(false);
    const [showArchivePrompt, setShowArchivePrompt] = React.useState(false);
    const parentRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [steps, SetSteps] = React.useState(defaultSteps)
    const [users, setUsers] = React.useState<[]>([])
    const {lastMessage, sendMessage} = useWebSocket(socketUrl,
        {
            queryParams: {userId: `${user?.userId}`},
            onOpen: () => console.log('DEBUG: web socket opened'),
            //Will attempt to reconnect on all close events, such as server shutting down
            shouldReconnect: (closeEvent) => true,
        });

    useEffect( () => {
        if (params.activityId) setActivityId(params.activityId);

    }, [params.activityId])


    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
            setViewportWidth(window.innerWidth);
        }
        if (queryParams.get('_id')) {
            setActivityId(queryParams.get('_id') || '');
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const headerHeight = isDesktop ? 80 : 48;
    const otherFixedHeight = isDesktop ? 32 : 36; //Height of grid containing action button

    const gridHeight = `calc(${viewportHeight}px - ${headerHeight}px - ${otherFixedHeight}px)`;
    const gridWidth = `calc(${viewportWidth}px - 95px)`;
    const calculateSteps = ( tmpActivity: ActivityType) => {
        let tmpSteps: { label: string; component: Steps; }[] = [];
        const showStep = (stepName: Steps) => {
            if(stepName === Steps.Customers) return tmpActivity?.selectedTemplates.some( element => /(Registration|Invitation|Survey)/.test(element))
            if(stepName === Steps.SaveTheDate) return tmpActivity?.selectedTemplates.some( element => /(Save the date)/.test(element))
            if(stepName === Steps.Invitation) return tmpActivity?.selectedTemplates.some( element => /(Registration|Invitation)/.test(element))
            if(stepName === Steps.Reminder) return tmpActivity?.selectedTemplates.some( element => /(Invitation)/.test(element))
            if(stepName === Steps.PreEventInfo) return tmpActivity?.selectedTemplates.some( element => /(Pre-event info)/.test(element))
            if(stepName === Steps.WebinarUrl) return tmpActivity?.selectedTemplates.some( element => /(Webinar)/.test(element))
            if(stepName === Steps.SendSurvey) return tmpActivity?.selectedTemplates.some( element => /(Survey)/.test(element))
            if(stepName === Steps.Survey) return tmpActivity?.selectedTemplates.some( element => /(Survey)/.test(element))
            if(stepName === Steps.Summary) return true
        }

        if (tmpActivity && tmpActivity.templateSuite) {
            if (tmpActivity.templateSuite.type === 'RepEmail') {
                tmpSteps = [
                    ...defaultSteps,
                    ...[
                        {label: 'Rep Email(s)',  component: Steps.RepEmail},
                        {label: 'Summary',  component: Steps.Summary}
                    ]
                ]
            } else if (tmpActivity.templateSuite.type === 'BookingEmail') {
                tmpSteps = [
                    ...defaultSteps,
                    ...[
                        {label: 'Booking Email(s)',  component: Steps.RepEmail},
                        {label: 'Summary',  component: Steps.Summary}
                    ]
                ]
            } else {
                tmpSteps = [
                    ...defaultSteps,
                    ...[
                        {label: 'Customers',  component: Steps.Customers},
                        {label: 'Save the date',  component: Steps.SaveTheDate},
                        {label: tmpActivity && tmpActivity.selectedTemplates.includes('Invitation') ? 'Invitation' :'Monitor registration', component: Steps.Invitation},
                        {label: 'Reminder', component: Steps.Reminder},
                        {label: 'Pre-event info', component: Steps.PreEventInfo},
                        {label: 'Webinar link', component: Steps.WebinarUrl},
                        {label: 'Ask for feedback', component: Steps.SendSurvey},
                        {label: 'Feedback',  component: Steps.Survey},
                        {label: 'Summary',  component: Steps.Summary}
                    ].filter( t => showStep(t.component))
                ]
            }
        } else {
            tmpSteps = defaultSteps;
        }

        return tmpSteps;
    }

    const fetchActivity = async () => {
        try {
            const tmpActivity: ActivityType = await getActivity(activityId);
            setUsers(await getUsers(tmpActivity.countries, tmpActivity.responsible));
            setParticipants(tmpActivity.participants.map( (participant: ParticipantType) => {
                // @ts-ignore
                return {...participant, updatedAt: format(parseISO(participant.updatedAt), "yyyy-MM-dd HH:mm")}
            }));
            if ( tmpActivity ) {
                setIsEditor(
                    user?.userId === tmpActivity?.responsible ||
                    userHasRole( ['Editor', 'Administrator']) ||
                    ( tmpActivity.templateSuite.type === 'Event' &&  userHasRole(['KAM']) && userHasCountries(tmpActivity.countries))
                );
                const repEmailCountryEditor = userHasRole(['RepEmail Editor']) && tmpActivity?.countries.includes(user?.countries ? user.countries[0] : '');
                setRepEmailEditor({
                    ...repEmailEditor,
                    editor: repEmailCountryEditor
                })
                setAppHeader(tmpActivity.subject);
                console.log("DEBUG: Initial object", tmpActivity);
                setActivity({
                    ...tmpActivity,
                    status: tmpActivity.status === 6 && new Date(tmpActivity.end) < new Date ? 7: tmpActivity.status,
                    statusText: tmpActivity.status === 6 && new Date(tmpActivity.end) < new Date ? 'Send survey URL' : tmpActivity.statusText
                });

                if (tmpActivity.templateSuite && tmpActivity.templateSuite.templates) {
                    const tmpSteps = calculateSteps(tmpActivity);
                    tmpSteps.forEach( (step, index) => {
                        if (index === tmpActivity?.status) {
                            setActiveStep(index);
                            setCurrentStep(index);
                        }
                    })
                    if (!isEmbedded && tmpActivity.isApproved) {
                        if (['RepEmail', 'BookingEmail'].includes(tmpActivity?.templateSuite?.type??'') && tmpActivity.participants.length) {
                            setActiveStep(tmpSteps.length - 1)
                        }
                        else if ( tmpActivity.surveyUrlSent ) {
                            setActiveStep(tmpSteps.length - 2)
                        }
                        else if (tmpActivity.invitationSent || tmpActivity.participants.find( (participant: ParticipantType) => participant.participate)) {
                            setActiveStep(tmpSteps.length - 1)
                        }
                    }
                    if (externalOrigin && tmpActivity?.templateSuite.type === 'RepEmail') setActiveStep(3)
                    if (externalOrigin && tmpActivity?.templateSuite.type !== 'RepEmail') setActiveStep(4)
                    if (externalOrigin && tmpActivity?.selectedTemplates.length === 1 && tmpActivity?.selectedTemplates[0] === 'Survey') setActiveStep(5)

                    SetSteps(tmpSteps);
                    if (queryParams.get('goTo') && queryParams.get('goTo') === 'WaitingList') {
                        setActiveStep(Steps.Customers)
                    }
                }
            } else {
                console.log("DEBUG: Initial object", tmpActivity);
                history(`/activity`);
            }
        } catch ( e: any ) {
            await sendErrorMessage(e,  'front/activity/methods.getActivity', "Cannot Get Activity", enqueueSnackbar, activityId);
        }
    }
    useEffect(() => {
        if (lastMessage !== null) {
            ( async () => {
                await webSocketHandler(
                    {
                        payload: JSON.parse(lastMessage.data),
                        activity,
                        activityId,
                        setActivity,
                        setParticipants,
                        participants,
                        fetchActivity,
                        enqueueSnackbar
                    })
            })();
        }

    }, [lastMessage]);

    const copyThisActivity = async () => {
        if(window.confirm('Do you really want to copy this activity?')) {
            setActivity(null);
            const newActivity: ActivityType = await copyActivity(activityId);
            setActivityId(newActivity._id);
        }

    }
    useEffect( () => {
        if (activityId) {
            ( async () => {
                setWaitingForBackendMessage('Fetching activity...');
                await fetchActivity();
                setWaitingForBackendMessage('');
            })();
        }
    },[activityId])
    useEffect( () => {
        if ( activity ) {
            const tmpSteps = calculateSteps(activity);

            tmpSteps.map( step => {
                const hasInvitationTemplate = !!(activity.templateSuite && activity.templateSuite.templates && activity.templateSuite.templates.find((template: TemplateType) => (template.type === 'E-MAIL INVITATION')));
                switch (step.component){
                    case Steps.Invitation:
                        return {
                            ...step,
                            label: hasInvitationTemplate && activity && activity.invitationSent ? 'Monitor sent invitations' : step.label
                        };
                    case Steps.WebinarUrl:
                        return {
                            ...step,
                            label: activity && activity.webinarUrlSent ? 'Monitor sent Webinar URL' : step.label
                        }
                }
                return step;
            })
            tmpSteps.forEach( (step, index) => {
                if (index === activity?.status) {
                    setCurrentStep(index);
                }
            })
            SetSteps(tmpSteps);

            setIsReadOnly(!!activity.isApproved);
            if ( user?.role === 'Standard' && user?.userId !== activity.responsible) {
                setIsReadOnly(true);
            }

        }
    },[activity])

    useEffect(() => {
        if (activity) {
            if (!isEmbedded) {
                setActions(
                    {
                        Delete: {
                            label: 'Delete',
                            disabled: !!(activity.archived || !isEditor || isReadOnly || activity.participants.length || activity.approvalCodes.length || activity.approvalCode),
                            onClick: () => {
                                if(window.confirm('Are you sure you want to delete this activity?')) {
                                    ( async() => {
                                        setWaitingForBackendMessage('Just a sec or two, I am deleting the activity...')
                                        await deleteActivity(activity._id);
                                        enqueueSnackbar(`Activity is deleted`, {variant: 'success'});
                                        setWaitingForBackendMessage('');
                                        setTimeout( () => {
                                            history(`/`);
                                        })

                                    })()
                                }
                            }
                        },
                        Copy : {
                            label: 'Copy',
                            disabled: !activity.templateSuite?.isRepEnabled ,
                            onClick: () => {
                                if(window.confirm('Would you like to make a copy of this activity?')) {
                                    ( async() => {
                                        setWaitingForBackendMessage('Just a sec or two, I am copying the activity...')
                                        const newActivity: ActivityType = await copyActivity(activity._id);
                                        setWaitingForBackendMessage('');
                                        history(`/activity/?_id=${newActivity._id}`);
                                    })()
                                }

                            }
                        },
                        Archive: {
                            label: 'Archive',
                            disabled: !isEditor || isReadOnly  || activity.archived,
                            onClick: async () => setShowArchivePrompt(true)
                        },
                    }
                )
            }

        }

    }, [activity?.archived, activity?.participants, activity?.approvalCode, activity?.approvalCodes])
    useEffect( () => {
        ( async () => {
            try {
                const types = ['payload', 'templateSuitePayload', 'templatePayload', 'templateFieldPayload'];
                for (const type of types) {
                    if (activityPayload[type] && Object.keys(activityPayload[type]).length > 0) {
                        console.log('DEBUG: activityPayload', activityPayload)
                        if (type !== 'templateFieldPayload') setWaitingForBackendMessage('Saving changes...');

                        let payload = { [type]: activityPayload[type] };

                        if (type === 'payload') payload.diff = compareObjects(activity, {...activity, ...activityPayload[type]});

                        await saveActivity(activityId, payload, sendMessage);

                        if (activityPayload.refreshActivity) {
                            setActivity(handlePayload(payload, JSON.parse(JSON.stringify(activity))));
                        }

                        setActivityPayload({refreshActivity: false, [type]: {}, diff: {}});
                        if (type !== 'templateFieldPayload') setWaitingForBackendMessage('');
                    }
                }

            } catch ( e: any ) {
                await sendErrorMessage(e,  'front/activity/methods.saveActivity', "Cannot Save Activity", enqueueSnackbar, activity?._id);
                setActivityPayload( {refreshActivity: false, payload: {}, templateFieldPayload: {}, diff: {}, templateSuitePayload: {}, templatePayload: {}});
            }
            finally {

            }

        })();
    }, [activityPayload])
    const getStepContent = (step: number) => {
        if ( steps.length) {
            const tmpStep = steps[step].component;
            switch (tmpStep) {
                case 0:
                    return <Details copyActivity={copyThisActivity} />;
                case 1:
                    return <Templates />;
                case 2:
                    return <Approval lastMessage={lastMessage} />;
                case 3:
                    return <Customers />;
                case 4:
                    return <RepEmails />;
                case 5:
                    return <SaveTheDate />;
                case 6:
                    return <Invitation />;
                case 7:
                    return <SendReminder />;
                case 8:
                    return <PreEventInfo />;
                case 9:
                    return <SendMeetingDetails />;
                case 10:
                    return <SendSurvey/>;
                case 11:
                    return <Survey/>;
                case 12:
                    return <Summary/>;
            }
        }

    }

    const handleStep = (step: number) => () => {
        if ( activity  ) {
            setActiveStep(step);
            setCurrentStep(step);
        }
    };

    const getActiveStepText = () => steps && steps[activeStep].label;

    function isStepComplete(step: number) {
        if (activity)
            return step < activity.status;
        else
            return false;
    }

    return (
        <ActivityContext.Provider value={
            {
                isArchived: activity?.archived,
                activity: activity??undefined,
                setActivityPayload,
                isReadOnly,
                setActivity,
                participants,
                isEmbedded,
                isEditor,
                repEmailEditor,
                activeStep,
                currentStep,
                users,
                sendMessage
            }}>
            <Grid container sx={{width: gridWidth, pt: isEmbedded ? '0px' :  isDesktop ? `calc(${headerHeight}px)` : '60px', ml: '20px'}}>
            <Grid item xs={10}>
                <Typography variant="h6" fontSize={!isDesktop ? 15 : '1.25rem'}>
                    {activity?.subject} {'>>'} {getActiveStepText()}
                </Typography>
            </Grid>
            <Grid item xs={2} textAlign={'right'}>
                <ActionBar actions={actions} isDesktop={isDesktop}/>
            </Grid>
            {
                !isEmbedded &&
                <Grid item xs={1}>

                    <Fade in={ activity && activity.templateSuiteId ? true: false} timeout={1000} ref={parentRef}>
                            <Stepper orientation={"vertical"} nonLinear activeStep={currentStep} >
                                {
                                    steps.map((step, index) => {
                                        const stepProps: { completed?: boolean } = {};
                                        const buttonProps: { optional?: React.ReactNode } = {};
                                        return (
                                            <Step key={step.label} completed={isStepComplete(index)} {...stepProps} style={{color: 'blue'}}>
                                                <StepButton
                                                    onClick={handleStep(index)}
                                                    {...buttonProps}
                                                >
                                                    <Typography style={{ fontSize: 9 }}>{step.label}</Typography>
                                                </StepButton>
                                            </Step>
                                        );
                                    })}
                            </Stepper>
                    </Fade>
                </Grid>


            }
                <Grid item xs={11} sx={{  maxHeight: gridHeight , overflow: 'auto',width: '100%', maxWidth: '99%'}}>
                    {activity && getStepContent(activeStep)}
                </Grid>
        </Grid>
            <ConfirmArchive
                confirmText={'Archive'}
                cancelText={'Cancel'}
                show={showArchivePrompt}
                onConfirm={ async()=> {
                    if (activity?._id) {
                        setWaitingForBackendMessage('Archiving...')
                        const tmpActivity = await archiveActivity(activity?._id);
                        setActivity(tmpActivity);
                        setAppHeader(tmpActivity.subject);
                        setParticipants(tmpActivity.participants);
                        setShowArchivePrompt(false);
                        setWaitingForBackendMessage('');
                    }
                }}
                onClose={()=>setShowArchivePrompt(false)}
                title={`Do you really want to archive this ${activity?.templateSuite?.type}?`}
                body={
                    <nav aria-label="main mailbox folders">
                        {
                        (activity?.templateSuite?.type === 'RepEmail' || activity?.templateSuite?.type === 'BookingEmail') ?
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <InfoIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={`By archiving this ${activity?.templateSuite?.type}, its status will change to 'Archived', and the text '[ARCHIVED]' will be added to its name. Consequently, it will no longer be selectable in eConnect.`} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        :
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <InfoIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="By archiving, participants who have given their consent either during registration or at an earlier time will have their names, emails, and addresses preserved. All other event-related data will be permanently deleted." />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <InfoIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="By archiving, participants who have not given consent will have all their data permanently deleted." />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        }


                </nav>
            }/>
      </ActivityContext.Provider>
    );
}
