import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import React from "react";
interface WaitingListProps {
    limitation: LimitationType;
    disabled: boolean;
    save: (limitation: LimitationType) => void;
}
export const WaitingList = ({disabled, limitation, save }: WaitingListProps) => {
    const handleWaitingListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enabled = (event.target as HTMLInputElement)?.value === 'true';
        save({...limitation, waitingList: {enabled}});
    }
    return (
        <Box hidden={disabled}>
    <FormControl disabled={disabled}>
        <FormLabel id="demo-radio-buttons-group-label">Would you like to enable a waiting list?</FormLabel>
        <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={limitation.waitingList?.enabled}
            onChange={handleWaitingListChange}
        >
            <FormControlLabel
                value={false}
                control={<Radio size={'small'} />}
                label="No"
            />
            <FormControlLabel value={true} control={<Radio size={'small'} />} label="Yes" />
        </RadioGroup>
    </FormControl>
        </Box>
    )
}