import React, {useContext} from 'react';
import {ActivityContext} from "../../ActivityContext";
import {ParticipantTabs} from "../ParticipantTabs";
import {useLocation} from "react-router-dom";


export const Customers = () => {
    const queryParams = new URLSearchParams(useLocation().search);
    const {activity, participants, isEditor,isEmbedded}  = useContext(ActivityContext);
    const [defaultTab] = React.useState<number>(queryParams.get('goTo') === 'WaitingList' ? 2 : activity.invitationSent ? 3 : 0);
    const [registrationQuestions] = React.useState<{question: string, question2: string, questions?: TemplateQuestionType[]} | null>({
        question: '',
        question2: '',
        questions: []
    });
    React.useEffect(() => {
        queryParams.delete('goTo');
    }, [])
    return (
        <ParticipantTabs
            tab1={{
                label: 'All',
                canSendEmail: false,
                canAddParticipant: true,
                canDeleteParticipant: true,
                canSelectCustomers: false,
                participants: participants,
                showField: 'invitationSent',
                title: 'All customers',
                display: !isEmbedded
                }}
            tab2={{
                canSendEmail: false,
                canAddParticipant: false,
                canDeleteParticipant: true,
                canSelectCustomers: false,
                label: 'Received invitation',
                participants: participants.filter( (participant: ParticipantType) => participant.invitationSent),
                showField: 'invitationSent',
                title: 'Invited customers',
                display: !isEmbedded
            }}
            tab3={{
                label: 'Waiting List',
                participants: participants.filter( (participant: ParticipantType) => participant.participate && participant.waitingList),
                showField: 'invitationSent',
                question: registrationQuestions?.question,
                questions: registrationQuestions?.questions,
                title: 'Customers in Waiting List',
                display: !isEmbedded,
                canSendEmail: false,
                canAddParticipant: false,
                canDeleteParticipant: false,
                canSelectCustomers: false,
                canApproveWaitingList: true
            }}
            tab4={{
                label: 'Registered',
                participants: participants.filter( (participant: ParticipantType) => participant.participate && !participant.waitingList),
                showField: 'invitationSent',
                question: registrationQuestions?.question,
                questions: registrationQuestions?.questions,
                title: 'Registered customers',
                display: !isEmbedded,
                canSendEmail: false,
                canAddParticipant: false,
                canDeleteParticipant: true,
                canSelectCustomers: false,
            }}
            isReadOnly={!isEditor}
            defaultTab={defaultTab}
        />
    )
}
