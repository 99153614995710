import {Box, Tab, Tabs} from "@mui/material";
import React, {useEffect} from "react";
import PropTypes, {InferProps} from "prop-types";
import {TabPanel} from "../../../components/TabPanel";
import {Participants} from "./Participants";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        style: {padding: '12px 16px'}
    };
}
export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    marginLeft: '0',
    marginTop: '0',
    marginBottom: '10px',
    flexDirection: 'column',
}))
export const ParticipantTabs: any = ({tab1, tab2, tab3, tab4, isReadOnly, action, defaultTab}: ParticipantTabsProps) => {
    const [value, setValue] = React.useState(defaultTab??0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);


    return (
        <Box sx={{  ml: '20px', mr: '20px' }} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab disabled={!tab1?.display} label={`${tab1?.label} (${tab1?.participants?.length})`} {...a11yProps(0)} />
                    <Tab disabled={!tab2?.display} label={`${tab2?.label} (${tab2?.participants?.length})`} {...a11yProps(1)} />
                    {
                        tab3 && <Tab disabled={!tab3?.display} label={`${tab3?.label} (${tab3?.participants?.length})`} {...a11yProps(2)} />
                    }
                    {
                        tab4 &&  <Tab label={`${tab4?.label} (${tab4?.participants?.length})`} {...a11yProps(3)} />
                    }
                </Tabs>
            </Box>
            {tab1?.display ?
            <TabPanel value={value} index={0}>
                <StyledPaper elevation={24} >
                    <Participants
                        participants={tab1.participants}
                        canAddParticipant={tab1.canAddParticipant}
                        canDeleteParticipant={tab1.canDeleteParticipant}
                        canSelectCustomers={tab1.canSelectCustomers}
                        canSendEmail={tab1.canSendEmail}
                        canApproveWaitingList={false}
                        showField={tab1.showField}
                        title={tab1.title}
                        actionTitle={tab1.actionTitle}
                        action={action}
                        freeActionTitle={tab1.freeActionTitle}
                        isReadOnly={isReadOnly}
                        display={tab1.display}
                    />
                </StyledPaper>
            </TabPanel>
                : ''
            }
            {tab2?.display ?
            <TabPanel value={value} index={1}>
                <StyledPaper elevation={24} >
                    <Participants
                        canAddParticipant={tab2.canAddParticipant}
                        canDeleteParticipant={tab2.canDeleteParticipant}
                        canSendEmail={tab2.canSendEmail}
                        canSelectCustomers={tab2.canSelectCustomers}
                        canApproveWaitingList={false}
                        participants={tab2.participants}
                        showField={tab2.showField}
                        title={tab2.title}
                        actionTitle={tab2.actionTitle}
                        action={action}
                        freeActionTitle={tab2.freeActionTitle}
                        isReadOnly={isReadOnly}
                        display={tab2.display}
                    />
                </StyledPaper>
            </TabPanel>
                : ''
            }
            {tab3 && tab3?.display &&
            <TabPanel value={value} index={2}>
                <StyledPaper elevation={24} >
                    <Participants
                        canAddParticipant={tab3.canAddParticipant}
                        canDeleteParticipant={tab3.canDeleteParticipant}
                        canSendEmail={tab3.canSendEmail}
                        canSelectCustomers={tab3.canSelectCustomers}
                        canApproveWaitingList={tab3.canApproveWaitingList}
                        participants={tab3.participants}
                        showField={tab3.showField}
                        title={tab3.title}
                        actionTitle={tab3.actionTitle}
                        action={action}
                        freeActionTitle={tab3.freeActionTitle}
                        question={tab3.question}
                        questions={tab3.questions}
                        isReadOnly={isReadOnly}
                        display={tab3.display}
                    />
                </StyledPaper>
            </TabPanel>
            }
            {
                tab4 &&
                <TabPanel value={value} index={3}>
                    <StyledPaper elevation={24} >
                        <Participants
                            canAddParticipant={tab4.canAddParticipant}
                            canDeleteParticipant={tab4.canDeleteParticipant}
                            canSendEmail={tab4.canSendEmail}
                            canSelectCustomers={tab4.canSelectCustomers}
                            canApproveWaitingList={false}
                            participants={tab4.participants}
                            showField={tab4.showField}
                            title={tab4.title}
                            actionTitle={tab4.actionTitle}
                            action={action}
                            question={tab4.question}
                            questions={tab4.questions}
                            freeActionTitle={tab4.freeActionTitle}
                            isReadOnly={isReadOnly}
                            display={tab4.display}
                        />
                    </StyledPaper>
                </TabPanel>
            }
        </Box>
    );
}


export interface ParticipantTabsProps {
    tab1?: ParticipantTabsTabProps,
    tab2?: ParticipantTabsTabProps,
    tab3?: ParticipantTabsTabProps,
    tab4?: ParticipantTabsTabProps,
    isReadOnly: boolean,
    action?: (participantIds: string[]) => void,
    defaultTab?: number
}

export interface ParticipantTabsTabProps {
    label: string,
    canAddParticipant: boolean,
    canDeleteParticipant: boolean,
    canApproveWaitingList?: boolean,
    canSendEmail: boolean,
    participants: ParticipantType[],
    showField: string,
    title: string,
    freeActionTitle?: string,
    actionTitle?: string,
    question?: string,
    questions?: TemplateQuestionType[],
    display: boolean,
    canSelectCustomers: boolean
}
