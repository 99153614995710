import {createContext} from 'react'

interface ActivityContextType {
    activity: any | ActivityType;
    setActivity?: any;
    participants: any[];
    setActivityPayload?: any;
    isReadOnly?: boolean;
    isArchived?: boolean;
    isEmbedded?: boolean;
    isEditor?: boolean;
    repEmailEditor?: any;
    activeStep?: number;
    currentStep?: number;
    users?: any[];
    sendMessage?: any;
}
export const ActivityContext = createContext<ActivityContextType>({activity: {}, participants: []});
