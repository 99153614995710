import React, { useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    IconButton,
    Tooltip,
    Typography,
    FormControlLabel,
    ThemeProvider,
    TextField,
    Grid,
    FormControl,
    RadioGroup,
    Radio,
    Dialog,
    DialogContent,
    FormLabel
} from "@mui/material";
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import { ActivityContext } from "../../../../ActivityContext";

import {TemplateSuiteContext} from "../../TemplateSuiteContext";
import {RichTextField} from "../../../../../../components/EditDialog";
import {theme} from "../../../../../../components/SmallTheme";
import {HowTo} from "../../../HowTo";
import DialogActions from "@mui/material/DialogActions";
import {LimitCategory} from "./LimitCategory";
import {WaitingList} from "./WaitingList";

interface LimitParticipantsProps {
    template: TemplateType;
    country: string;
    templateFields: TemplateFieldType[];
}

export const LimitParticipants = ({ template, country,templateFields }: LimitParticipantsProps) => {
    const [limitation, setLimitation] = useState<LimitationType>(template?.limitation??{status: 'no-limitation', max: 0, message: 'Thank you for your interest. Sadly, the event is now fully booked.'});
    const [selectedField, setSelectedField] = useState<TemplateFieldType | undefined>(templateFields.find( (field: TemplateFieldType) => field.name === limitation.details?.field));
    const {handleTemplateUpdateV2} = useContext(TemplateSuiteContext);
    const { isArchived, isEditor, activity, setActivityPayload } = useContext(ActivityContext);
    const anchorRef = useRef(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [hasLimitation, setHasLimitation] = useState(template?.limitation?.status !== 'no-limitation');
    const [totalCategoryLimits, setTotalCategoryLimits] = useState(0);
    const [categoryFields, setCategoryFields] = useState<TemplateFieldType[]>([]);
    const [error, setError] = useState(false);

    // Popover open and close handlers
    const handlePopoverOpen = () => {
        setPopoverOpen(true);
    };
    const handlePopoverClose = () => {
        setPopoverOpen(false);
    };
    const save = (inputLimitation: LimitationType = limitation) => {
        handleTemplateUpdateV2({
            template,
            activity,
            setActivityPayload,
            fieldName: 'limitation',
            fieldValue: inputLimitation,
            country
        })
        setLimitation(inputLimitation);
    }
    const handleMaxNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const max = Number(event.target.value);
        const tmpLimitation = {...limitation, max};
        if (totalCategoryLimits > max) setError(true);
        setSelectedField(undefined);
        setSelectedField(templateFields.find( (field: TemplateFieldType) => field.name === tmpLimitation.details?.field));
        save(tmpLimitation);
    };

    const handleLimitMessageChange = (value: string) => {
        save({...limitation, message: value});
    };


    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        save({...limitation, status: (event.target as HTMLInputElement).value});
        setHasLimitation((event.target as HTMLInputElement).value !== 'no-limitation');
    }
    useEffect(() => {
        if (selectedField?.name !== limitation.details?.field) {
            setError(false)
            save({
                ...limitation,
                details: {
                    ...limitation.details,
                    field: selectedField?.name,
                    values: selectedField?.choices?.map( (choice: string) => {
                        return {value: choice, max: 0}
                    })
                }});
        }
    }, [selectedField])
    return (
        <ThemeProvider theme={theme}>
            <Tooltip title="Limit the number of participants that can sign up">
                <IconButton onClick={handlePopoverOpen} ref={anchorRef}>
                    <PersonAddDisabledIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={popoverOpen} onClose={handlePopoverClose} fullWidth maxWidth={'md'}
                    PaperProps={{
                        style: {
                            maxWidth: '800px', // Set the max width here
                            width: '100%', // Ensures the dialog is responsive
                        }
            }}>
                <DialogContent>
                    <Grid container p={2} spacing={2} width={'750px'}>
                        <Grid item xs={12}><HowTo videoName={'How-to-limit-participants.mov'} /></Grid>
                        <Grid item xs={12} >
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Would you like to limit the number of participants?</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={limitation.status}
                                    onChange={handleStatusChange}
                                >
                                    <FormControlLabel
                                        value="no-limitation"
                                        control={<Radio size={'small'} />}
                                        label="No limitations"
                                    />
                                    <FormControlLabel value="limitation" control={<Radio size={'small'} />} label="Limited" />
                                    <FormControlLabel value="limitation-per-category" control={<Radio size={'small'} />} label="Limits per category " />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}  >
                            <TextField
                                fullWidth
                                variant="standard"
                                sx={{fontSize: '14px'}}
                                label="Max number of participants"
                                disabled={!isEditor || isArchived || !hasLimitation}
                                defaultValue={limitation.max}
                                onBlur={handleMaxNumberChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={9} />

                        <Grid item xs={12} p={2} component="div" sx={{ '& > span': { display: 'inline-block', marginY: '8px' }, minHeight: '90px', width: '750px' }}>
                            <Typography component="div" variant="body1" style={{fontSize: '14px', color: 'gray', paddingBottom: '10px'}}>
                                Message to display when the limit is reached:
                            </Typography>
                            <RichTextField maxLength={500} onBlur={handleLimitMessageChange} defaultValue={limitation.message} readOnly={!isEditor || isArchived || (!hasLimitation)} fieldId={'limitMessage'} />
                        </Grid>
                        {limitation.status === 'limitation-per-category' &&
                            <LimitCategory
                                limitation={limitation}
                                totalCategoryLimits={totalCategoryLimits}
                                categoryFields={categoryFields}
                                setTotalCategoryLimits={setTotalCategoryLimits}
                                setError={setError}
                                save={save}
                                disabled={!isEditor || isArchived || !hasLimitation}
                                setSelectedField={setSelectedField}
                                selectedField={selectedField}
                                templateFields={templateFields}
                                error={error}
                                setCategoryFields={setCategoryFields}
                            />}

                        <Grid item xs={12} >
                            <WaitingList limitation={limitation} disabled={true} save={save} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePopoverClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};